"use client";
import {
  defineIcons,
  ExtraIcons,
  ExtraIconsProvider,
} from "@moller/design-system";
import {
  FilterIcon,
  Calendar,
  Speed,
  GasStation,
  Gearshift,
  ImageIcon,
} from "../components/icons";
import React from "react";

// NB! When adding new svg's, make sure to remove height and width properties in svg-code to allow proper size to be set.
const appIcons = defineIcons({
  filter_icon: {
    filled: FilterIcon,
    options: { fit: "width", scale: 1 },
  },
  new_calendar: {
    filled: Calendar,
    options: { fit: "width", scale: 1 },
  },
  speed: {
    filled: Speed,
    options: { fit: "width", scale: 1 },
  },
  new_gas_station: {
    filled: GasStation,
    options: { fit: "width", scale: 1 },
  },
  gearshift: {
    filled: Gearshift,
    options: { fit: "width", scale: 1 },
  },
  image_icon: {
    filled: ImageIcon,
    options: { fit: "height", scale: 1.1 },
  },
});
type AppIcons = typeof appIcons;

declare module "@moller/design-system" {
  // eslint-disable-next-line
  interface ExtraIcons extends AppIcons {}
}

export const ExtraIconsRegistry = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    // @ts-ignore    TODO: Find solution without ts-ignore. ExtraIconsProvider does not accept children: React.ReactNode
    <ExtraIconsProvider icons={appIcons as ExtraIcons}>
      {children}
    </ExtraIconsProvider>
  );
};
