import {
  Department,
  DepartmentGroup,
  StandaloneDepartment,
} from "./departments";

export const departmentGroups: DepartmentGroup[] = [
  {
    name: "Albjerk Bil",
    slug: "albjerk",
    departments: [
      {
        name: "Albjerk Bil",
        id: "e9d362fe-e664-4dfb-85ac-81f1440d2fc6",
        slug: "albjerk-bil",
      },
      {
        name: "Albjerk Bil Ringerike",
        id: "ede5be07-cfd0-441f-940e-3150377d837e",
        slug: "albjerk-bil-ringerike",
      },
    ],
  },
  {
    name: "Autoria",
    slug: "autoria",
    departments: [
      {
        name: "Autoria Kolbotn",
        id: "a208afb3-10e3-46ef-9b29-bd1a8716c0e8",
        slug: "autoria-kolbotn",
      },
      {
        name: "Autoria Nordlie",
        id: "5570a399-7d87-480d-9cb2-6ea894f0c752",
        slug: "autoria-nordlie",
      },
      {
        name: "Autoria Rød",
        id: "8d54cfa4-8da0-47fa-acd0-17ead79f92bd",
        slug: "autoria-rod",
      },
      {
        name: "Autoria Ski",
        id: "5456765d-28c6-4dc0-b65c-5c92927dcc3f",
        slug: "autoria-ski",
      },
      {
        name: "Autoria Mysen",
        id: "1c4bc052-83fc-4635-9e2f-d607b056f671",
        slug: "autoria-mysen",
      },
    ],
  },
  {
    name: "Dahles Auto",
    slug: "dahles-auto",
    departments: [
      {
        name: "Dahles Auto Halden",
        id: "e504d8c3-2900-44e8-ba53-750ec3ec67e8",
        slug: "dahles-auto-halden",
      },
      {
        name: "Dahles Auto Fredrikstad",
        id: "a701f4b6-0433-4619-83b1-6dfa6d084341",
        slug: "dahles-auto-fredrikstad",
      },
      {
        name: "Dahles Auto Bruktbilsenter",
        id: "b9e8d45d-fd2d-4741-bfa2-8e62fb502b1b",
        slug: "dahles-auto-bruktbilsenter",
      },
      {
        name: "Dahles Auto Sarpsborg",
        id: "2521a92c-7375-4406-8a1d-6b98d50749aa",
        slug: "dahles-auto-sarpsborg",
      },
    ],
  },
  {
    name: "Frydenbø Bil",
    slug: "frydenbo-bil",
    departments: [
      {
        name: "Frydenbø Bil Ørsta",
        id: "75c24ad2-e0c8-4161-ae81-a3942012e804",
        slug: "frydenbo-bil-orsta",
      },
      {
        name: "Frydenbø Bil Førde",
        id: "dd12e06b-ccdd-4d18-9547-ab9bc81e6224",
        slug: "frydenbo-bil-forde",
      },
      {
        name: "Frydenbø Bil Stryn",
        id: "4ca86fd6-e196-4ef1-88c7-e4568b906f30",
        slug: "frydenbo-bil-stryn",
      },
      {
        name: "Frydenbø Bil Sogndal",
        id: "b5faec47-1c41-4543-9225-c1560cc2a921",
        slug: "frydenbo-bil-sogndal",
      },
      {
        name: "Frydenbø Bil Molde",
        id: "363f8d51-12a9-49fb-9a70-4e78279d4998",
        slug: "frydenbo-bil-molde",
      },
      {
        name: "Frydenbø Bil Ålesund",
        id: "ca7b2e6c-4d72-42ef-9d1f-af9bb117dd96",
        slug: "frydenbo-bil-aalesund",
      },
    ],
  },
  {
    name: "Gromstad",
    slug: "gromstad",
    departments: [
      {
        // GROMSTAD_AUTO
        name: "Gromstad Auto",
        id: "ba4856ac-46db-451f-897f-e6bc31fc97c6",
      },
      {
        // GROMSTAD_BIL_OG_BAT
        name: "Gromstad Bil & Båt",
        id: "160583b0-4493-4be6-8ea9-2af43abbcae7",
      },
      {
        // GROMSTAD_STOA
        name: "Gromstad Motor Stoa",
        id: "af3f99fe-f948-424e-a41e-4a65c269b1d5",
      },
    ],
  },
  {
    name: "Gumpens Auto",
    slug: "gumpens-auto",
    departments: [
      {
        name: "Gumpens Auto Grenland",
        id: "1821e10b-df18-4319-9ba7-1b3d82b236bb",
        slug: "gumpens-auto-grenland",
      },
      {
        name: "Gumpens Auto Lyngdal",
        id: "abf533c3-371d-4072-aa24-8fc2d1c25c81",
        slug: "gumpens-auto-lyngdal",
      },
      {
        name: "Gumpens Auto Mandal",
        id: "970d9d13-3b39-44eb-b279-50c3b97bb866",
        slug: "gumpens-auto-mandal",
      },
      {
        name: "Gumpens Auto Vest",
        id: "fa7d6e6d-ed75-4610-8112-03c51de9634a",
        slug: "gumpens-auto-vest",
      },
      {
        name: "Gumpens Auto Øst",
        id: "5f5b3652-3476-47a0-b96b-5c1f3b7a212e",
        slug: "gumpens-auto-ost",
      },
    ],
  },
  {
    name: "Mobile",
    slug: "mobile",
    departments: [
      {
        name: "Mobile Ljosland",
        id: "262e5b71-2672-4b4f-98b8-4ce34e0d2370",
        slug: "mobile-ljosland",
      },
      {
        name: "Mobile Hokksund",
        id: "39da874f-26a5-4044-b167-2e3c58b97201",
        slug: "mobile-hokksund",
      },
    ],
  },
  {
    name: "Slatlem",
    slug: "slatlem",
    departments: [
      {
        name: "Slatlem Bilsentrum",
        id: "82d21a4b-02ce-47e0-9ae2-720cddf59362",
        slug: "slatlem-bilsentrum",
      },
      {
        name: "Slatlem Molde",
        id: "30014b54-f0e1-4cc9-b60f-8c45b38021b0",
        slug: "slatlem-molde",
      },
    ],
  },
  {
    name: "Sulland",
    slug: "sulland",
    departments: [
      {
        name: "Sulland Elverum",
        id: "3fc08db8-9c5e-48c1-922c-2e55aed3f74b",
        slug: "sulland-elverum",
      },
      {
        name: "Sulland Harstad",
        id: "90defad7-834d-48b2-a31e-7e689ef421ff",
        slug: "sulland-harstad",
      },
      {
        name: "Sulland Verdal",
        id: "afe0c590-04b9-48ac-8c6d-8e63bd55de33",
        slug: "sulland-verdal",
      },
      {
        name: "Sulland Bardufoss",
        id: "e1ceca84-8e6b-4912-9845-c93ddbbd383d",
        slug: "sulland-bardufoss",
      },
      {
        name: "Sulland Bodø",
        id: "d260f45a-37e1-44fb-8861-6a10a6d0418b",
        slug: "sulland-bodo",
      },
      {
        name: "Sulland Mo",
        id: "7adc3374-05fc-4a3c-a22a-64e6bca08faa",
        slug: "sulland-mo",
      },
      {
        name: "Sulland Tromsø",
        id: "df84d9ac-6385-4ac3-adac-00bc6f4e9cba",
        slug: "sulland-tromso",
      },
      {
        name: "Sulland Otta",
        id: "6d353b5e-0ace-48a0-ae1b-4f970c016040",
        slug: "sulland-otta",
      },
    ],
  },
  {
    name: "Møller Bil",
    slug: "moller-bil",
    departments: [
      {
        name: "Møller Bil Outlet Alnabru",
        id: "fe6ffafc-3474-4847-87d7-816edbfccff0",
        slug: "moller-bil-bruktbil-outlet-alnabru",
      },
      {
        name: "Møller Bil Asker & Bærum",
        id: "c0ca8ab7-fe41-4b02-9ee0-c41dbf2be5bc",
        slug: "moller-bil-asker-baerum",
      },
      {
        name: "Møller Bil Bruktbilsenter Bergen",
        id: "b5ee1722-d97b-4682-9967-6b6fb4b97bf1",
        slug: "moller-bil-bruktbilsenter-bergen",
      },
      {
        name: "Møller Bil Bryn",
        id: "1bb7e62f-50e0-4ef8-90e6-f79f059ea377",
        slug: "moller-bil-bryn",
      },
      {
        name: "Møller Bil Egersund",
        id: "a684b54d-5109-465f-b5d4-ec8ff00cbad2",
        slug: "moller-bil-egersund",
      },
      {
        name: "Møller Bil Elverum",
        id: "2f076b00-15af-4194-b863-e8f00553b450",
        slug: "moller-bil-elverum",
      },
      {
        name: "Møller Bil Ensjø",
        id: "c3a4384c-5c04-4332-97e7-5ae26bbc6db0",
        slug: "moller-bil-ensjo",
      },
      {
        name: "Møller Bil Follo",
        id: "89121965-00df-4576-bd0c-af5d90482b81",
        slug: "moller-bil-follo",
      },
      {
        name: "Møller Bil Gjøvik",
        id: "b1ad0ef1-ce23-45de-906e-25ed082acc5e",
        slug: "moller-bil-gjovik",
      },
      {
        name: "Møller Bil Gran",
        id: "1476187e-b34d-4761-aab3-ddc9e3b93c60",
        slug: "moller-bil-gran",
      },
      {
        name: "Møller Bil Hamar",
        id: "6afbfff6-6676-47a1-8bfa-963aaa21bdcf",
        slug: "moller-bil-hamar",
      },
      {
        name: "Møller Bil Haugesund",
        id: "74867b29-2c28-4848-9e69-347186152585",
        slug: "moller-bil-haugesund",
      },
      {
        name: "Møller Bil Horten",
        id: "465236e4-26cc-45b9-8a31-532ec6c0d9f3",
        slug: "moller-bil-horten",
      },
      {
        name: "Møller Bil Bruktbilsenter Hvam",
        id: "9b9dd0b0-6574-4777-b595-93e2483b90c6",
        slug: "moller-bil-bruktbil-hvam",
      },
      {
        name: "Møller Bil Jessheim",
        id: "ee041b07-dc51-47b3-8b2d-8bade76e60e7",
        slug: "moller-bil-jessheim",
      },
      {
        name: "Møller Bil Kalbakken",
        id: "8920d3ad-b93b-4108-9b89-d9b9e476738e",
        slug: "moller-bil-kalbakken",
      },
      {
        name: "Møller Bil Kongsvinger",
        id: "cf553117-6986-461c-9a53-da478378f1c6",
        slug: "moller-bil-kongsvinger",
      },
      {
        name: "Møller Bil Lillehammer",
        id: "48ce3b38-266a-469e-acfd-0b43b4f60bda",
        slug: "moller-bil-lillehammer",
      },
      {
        name: "Møller Bil Orkdal",
        id: "9b974d70-a7f0-47c3-a829-f59e7fa4afce",
        slug: "moller-bil-orkdal",
      },
      {
        name: "Møller Bil Oslo Vest",
        id: "924e4e92-14ef-42d0-956a-395891465c59",
        slug: "moller-bil-oslo-vest",
      },
      {
        name: "Møller Bil Sandefjord",
        id: "79cfeb52-e153-4cde-abee-a94eff068cf2",
        slug: "moller-bil-sandefjord",
      },
      {
        name: "Møller Bil Stavanger",
        id: "5840e611-fe8d-4568-9727-8473ada99c54",
        slug: "moller-bil-stavanger",
      },
      {
        name: "Møller Bil Trondheim",
        id: "e40c1a0d-c89b-48ba-9f27-0cbc2603ad1d",
        slug: "moller-bil-trondheim",
      },
      {
        name: "Møller Bil Tønsberg",
        id: "cc368fe6-a260-426c-80b7-d72fd95138e9",
        slug: "moller-bil-tonsberg",
      },
    ],
  },
];

export const standaloneDepartments: StandaloneDepartment[] = [
  {
    id: "ab28197e-6430-4d40-999a-42ccc050b2d5",
    slug: "bil-i-nord-lofoten",
    name: "Bil i Nord Lofoten",
  },
  {
    id: "f7d5fa21-eceb-4118-bc76-64f6976e2eb3",
    slug: "bilsenteret-namsos",
    name: "Bilsenteret Namsos",
  },
  {
    id: "ca275a64-28a9-4e39-a378-d845ebd5389d",
    slug: "g-bil",
    name: "G-Bil",
  },
  {
    id: "e394b58f-cdf4-4fa5-8048-3ddbf09e6669",
    slug: "grenland-bilservice",
    name: "Grenland Bilservice",
  },
  {
    id: "84722dfc-b117-4637-af3b-0021e2bc7dbe",
    slug: "hammerfest-auto",
    name: "Hammerfest Auto",
  },
  {
    id: "8071ca87-0c52-457d-bc49-af9a879dd40f",
    slug: "hansens-bilverksted",
    name: "Hansens Bilverksted",
  },
  {
    id: "2dfe18f1-f5fe-4a78-91b0-9e47e48780b4",
    slug: "hmv-bilsenter",
    name: "HMV Bilsenter",
  },
  {
    id: "2f25e24b-c392-431d-a130-704c21ed3f3a",
    slug: "kirkenes-bil",
    name: "Kirkenes Bil",
  },
  {
    id: "0df4df5b-047b-4ae5-82a4-07a51ed9461c",
    slug: "motorpartner-namsos",
    name: "Motorpartner Namsos",
  },
  {
    id: "c2115f9f-3125-4b6d-b9a0-b5caa78c6300",
    slug: "fremme",
    name: "Fremme",
  },
  {
    id: "1da92197-62e7-45b6-aa8a-4cf729f1189a",
    slug: "nesbyen-auto",
    name: "Nesbyen Auto",
  },
  {
    id: "c70e107a-0bbf-4256-b2f1-cb8df2d780cf",
    slug: "odda-bilsenter",
    name: "Odda Bilsenter",
  },
  {
    id: "eb228d27-7867-4817-b26c-0854db918dea",
    slug: "sortland-auto",
    name: "Sortland Auto",
  },
  {
    id: "b1b63178-c6e6-41a3-a932-54058f9e5183",
    slug: "svebjorn-auto",
    name: "Svebjørn Auto",
  },
  {
    id: "4a539cce-5af8-4ee8-8431-7fd50e36865f",
    slug: "sveen-auto",
    name: "Sveen Auto",
  },
  {
    id: "7ad94800-c72f-4385-b21b-0b317ebd2664",
    slug: "valdres-auto",
    name: "Valdres Auto",
  },
  {
    id: "f93e068f-57ee-487b-8195-b616be993230",
    slug: "harila-vadso",
    name: "Harila Vadsø",
  },
  {
    id: "937df5d5-2316-4810-aec1-0628453d52b1",
    slug: "alta-motor",
    name: "Alta Motor",
  },
  {
    id: "9505d929-9552-447f-abae-70c7e8727634",
    slug: "sannan-bil",
    name: "Sannan Bil",
  },
];
