"use client";

import { createContext, useContext } from "react";
import { DealerCampaign } from "./campaign.types";

export const SanityCampaignContext = createContext<{
  dealerCampaigns: DealerCampaign[];
}>({
  dealerCampaigns: [],
});

export const SanityCampaignProvider = ({
  children,
  dealerCampaigns,
}: {
  children: React.ReactNode;
  dealerCampaigns: DealerCampaign[];
}) => {
  return (
    <SanityCampaignContext.Provider value={{ dealerCampaigns }}>
      {children}
    </SanityCampaignContext.Provider>
  );
};

export const useDealerCampaign = (dealerGroupName?: string) => {
  const campaigns = useContext(SanityCampaignContext).dealerCampaigns;

  const dealerCampaigns = campaigns.filter(
    (c) => dealerGroupName?.includes(c.dealerGroupName),
  );

  // TODO: Support more than 1 campaign per dealer group
  const dealerCampaign =
    dealerCampaigns.length > 0 ? dealerCampaigns[0] : undefined;

  return {
    dealerCampaign: dealerCampaign,
  };
};
