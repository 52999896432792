"use client";

import { styled } from "styled-components";
import NextLink from "../nextLink";
import { MMGLogo } from "../icons";
import { breakpointDesktop, breakpointMobile } from "@moller/design-system";
import { UnstyledList } from "../styled-common/unstyledList";
import { Link } from "@moller/design-system";
import { ArrowLink } from "./arrowLink";
import { footerPaddingInline } from "@/styles/mixins";

const FooterWrapper = styled.footer`
  padding-block: var(--moller-spacing-3xl) 72px;
  ${footerPaddingInline()}
  background-color: var(--moller-color-inverse-background);
  color: var(--moller-color-on-inverse-background);

  > div {
    max-width: 1728px;
    margin-inline: auto;
  }
`;

const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--moller-spacing-3xl) var(--moller-spacing-m);

  padding-bottom: var(--moller-spacing-xl);
  border-bottom: 1px solid #d0d3d9; // todo: replace with a design system color
  margin-bottom: var(--moller-spacing-xl);

  @media screen and (min-width: ${breakpointMobile}) {
    flex-direction: row;

    .bruktbil {
      width: 100%;

      > * {
        max-width: 352px;
      }
    }

    .contact {
      text-align: right;
    }
  }

  @media screen and (min-width: ${breakpointDesktop}) {
    flex-wrap: nowrap;

    .bruktbil {
      width: auto;
    }
  }
`;

const Heading = styled.h2`
  margin-bottom: var(--moller-spacing-s);
  font-size: var(--moller-type-small-body-font-size);
  font-weight: 700;
  line-height: 150%;
`;

const List = styled(UnstyledList)`
  li {
    margin-bottom: var(--moller-spacing-xxs);
  }
`;

export const Footer = () => {
  return (
    <FooterWrapper>
      <InformationWrapper>
        <div className="bruktbil">
          <Heading>Bruktbil</Heading>
          <p>
            Vi selger bruktbiler fra ulike forhandlere over hele Norge. Finn din
            neste bruktbil fra tusenvis av bruktbiler til salgs.
          </p>
          <br />
          <p>Bruktbil er et produkt fra Møller Mobility Group.</p>
        </div>
        <div>
          <Heading>Nyttig informasjon</Heading>
          <List>
            <li>
              <ArrowLink $as={NextLink} href="/innbytte-av-bil">
                Innbytte av bil
              </ArrowLink>
            </li>
            <li>
              <ArrowLink $as={NextLink} href="/personvern">
                Personvern
              </ArrowLink>
            </li>
            <li>
              <ArrowLink $as={NextLink} href="/informasjonskapsler">
                Informasjonskapsler
              </ArrowLink>
            </li>
          </List>
        </div>
        <div className="contact">
          <Heading>Kontakt oss</Heading>
          <List>
            <li>
              <p>Org.nr 943 733 988</p>
            </li>
            <li>
              <Link href="mailto:kundeservice@moller.no">
                kundeservice@moller.no
              </Link>
            </li>
          </List>
        </div>
      </InformationWrapper>
      <div>
        <MMGLogo />
      </div>
    </FooterWrapper>
  );
};
